<template>
  <div id="admin-real-estate-development-create" class="admin-view">
    <v-row no-gutters>
      <v-col md="12">
        <real-estate-development-create-form />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    RealEstateDevelopmentCreateForm: () =>
      import(
        "@/components/real-estate-development/RealEstateDevelopmentCreateForm.vue"
      )
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
